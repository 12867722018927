@import "../../node_modules/bootstrap/scss/bootstrap";
@import "https://cdn.jsdelivr.net/npm/bootstrap-dark-5@1.1.3/dist/css/bootstrap-night.min.css";

.endpoint-param {
  color: blue;
  font-weight: bolder;
}

.text-bold {
  font-weight: bolder;
}

.small-description {
  font-weight: lighter;
  font-size: small;
}

.no-body {
  color: indianred;
}

.card-header {
  cursor: pointer;
}
